.currency-field-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .input-currency {
    text-align: right;
    padding: 2px 20px 2px 5px;
    color: #000000;
  }
  
  .input-currency-unit {
    flex: 0 0;
    padding: 0;
    position: relative;
    left: -15px;
    font-size: 1em;
    color: #000000;
  }

  .input-currency[disabled] {
    color: #7b8a8b;
    
    & + .input-currency-unit {
      opacity: 0.5;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance:textfield;
}
