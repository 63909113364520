.notes-page {
  li {
    text-align: justify;
  }

  h1 {
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
  }
}
