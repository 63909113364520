.ReportBilancio {
  .indicator-card {
    border: 1px solid #ccc;
    padding: 7px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    min-width: 0;
    page-break-inside: avoid;

    p {
      text-align: left;
      margin-bottom: 0.6rem;
    }

    .indicator-name {
      flex: 1 1;
      font-size: 0.8em;
    }

    .indicator-value {
      font-size: 20px;
      text-align: center;
      font-weight: bold;
    }
  }

  .grid-1x3 {
    display: grid;
    column-gap: 0.6cm;
    row-gap: 0.6cm;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }

  .grid-2x3 {
    display: grid;
    column-gap: 0.6cm;
    row-gap: 0.6cm;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .grid-3x3 {
    display: grid;
    column-gap: 0.6cm;
    row-gap: 0.6cm;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
}
