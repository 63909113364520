.indicator-page {
  h1 {
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
  }
  h2 {
    margin-bottom: 0;
    margin-top: 0.4cm;
    font-size: 1.6rem;
    font-weight: bold;
  }
  h3 {
    margin-bottom: 0;
    margin-top: 0.3cm;
    font-size: 1.3rem;
    font-weight: bold;
  }

  p.note {
    white-space: pre-line;
  }

  p {
    text-align: justify;
  }

  p.kind-of-title {
    margin: 8px 0 0 0;
  }

  .pre {
    display: inline;
    white-space: pre-wrap;
  }
}
