.ReportBilancio {
  font-family: "Roboto Condensed", sans-serif;
  .page {
    page-break-after: always;
  }

  .break {
    page-break-after: always;
  }
}

.ReportBilancio:not(.print) {
  &.wrapper {
    background-color: #cccccc;
    padding: 10px;
  }
  .page {
    padding: 1cm;
    width: 21cm;
    margin: 0px auto;
    background-color: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  }
}

.ReportBilancio.print {
  .page {
    width: 100%;
  }
}

@page {
  size: A4;
}
