.bilancio-page {

  h1 {
    font-size: 2.3rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  table.recap-bilancio {
    width: 100%;
    table-layout: fixed;
    font-size: 12px;
    thead {
      th {
        background-color: #aaa;
        padding: 2px 5px;
        &:nth-child(1) {
          width: 80%;
        }
      }
    }
    tbody {
      tr:nth-child(even) {
        td {
          background-color: #efefef;
        }
      }
      tr {
        td {
          padding: 1px 5px;
        }
        td:last-child {
          text-align: right;
        }
        &.depth-1 {
          td {
            background-color: #dedede;
          }
          td:first-child {
            padding-left: 5px;
          }
        }
        &.depth-2 {
          td:first-child {
            padding-left: 15px;
          }
        }
      }
    }
  }

  h2 {
    margin-top: 1cm;
    font-size: 1.6rem;
    font-weight: bold;
  }

  p {
    white-space: pre-line;
  }
}