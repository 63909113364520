$carousel-width: 70vw;

.onboarding-root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100000;
  backdrop-filter: blur(5px);
  transition: opacity 500ms ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .slides {
    width: $carousel-width;
    max-width: 960px;
    height: calc(calc(calc(70vw * 450) / 960) + 25vh);
    max-height: calc(calc(calc(960px * 450) / 960) + 25vh);
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .slide {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      background-color: white;
      transition: transform 500ms ease-in-out, opacity 500ms ease-in-out;

      .slide-content {
        background-color: #188a37;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        height: calc(calc(70vw * 450) / 960);
        max-height: calc(calc(960px * 450) / 960);
      }

      .slide-message {
        padding: 50px 50px 20px;
        flex: 1 1 0%;
      }

      .slide-actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 30px;
      }


    }
  }

  .actions {
    position: absolute;
    top: 77vh;
    left: (100vw - $carousel-width) / 2;
    right: (100vw - $carousel-width) / 2;
  }

  .onboarding-image {
    width: 100%;
  }

  .slide-past {
    transform: translate(-$carousel-width);
    opacity: 0;
  }

  .slide-future {
    transform: translate($carousel-width);
    opacity: 0;
  }

  .onboarding-logo {
    position: absolute;
    left: 50px;
    bottom: 60px;
    width: 75%;
  }

  .skip-forever {
    margin-top: 20px;
    color: white;
    text-align: center;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .onboarding-close {
    color: white;
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 20px;
    cursor: pointer;
  }
}