.ImageField {
  position: relative;
  border: 1px dashed #aaa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 8px;

  input {
    display: none;
  }

  div.img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    .overlay {
      display: flex;
    }
  }
}
