.report-first-page {
  display: grid;
  grid-template-rows: 1.5cm 1cm 5.5cm 2cm 7.5cm 9.5cm;

  &:not(.print) {
    width: 21cm;
    height: 29.7cm;
  }
  grid-template-columns: [left] 50% [mid] 50% [right];
  grid-template-areas:
    ". giuffre"
    "company ."
    "company header"
    "author author"
    "infobilancio infobilancio"
    "rischio chart";

  .logo-giuffre {
    grid-area: giuffre;
    justify-self: end;
  }

  .logo-azienda {
    grid-area: company;
    justify-self: stretch;
    align-self: stretch;
    padding-left: 20px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: left;
    }
  }

  .header {
    grid-area: header;
    justify-self: stretch;
    align-self: center;
    text-align: right;
    padding-right: 20px;

    h1 {
      font-size: 3rem;
      font-weight: bold;
    }

    h2 {
      font-size: 1.5rem;
      margin: 0;
    }

    img {
      margin-top: 50px;
      max-width: 200px;
      max-height: 50px;
    }
  }

  .author {
    grid-area: author;
    padding: 40px 20px;
    font-size: 1.2em;
  }

  .info-bilancio {
    grid-area: infobilancio;

    table {
      table-layout: fixed;
      border-collapse: collapse;

      h1,
      h2 {
        font-weight: bold;
      }

      h1 {
        font-size: 2.3rem;
      }

      h2 {
        font-size: 1.5rem;
      }

      td {
        vertical-align: baseline;
      }

      td:first-child {
        width: 50%;
        text-align: right;
        padding-right: 40px;
      }

      td:last-child {
        padding-left: 40px;
        border-left: 4px solid #0e3572;
      }
    }
  }

  .rischio {
    grid-area: rischio;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    h2 {
      font-size: 1.4rem;
      font-weight: bold;
      text-align: center;
    }

    .box {
      width: 180px;
      height: 180px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      font-size: 80px;
    }
  }

  .chart {
    grid-area: chart;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
