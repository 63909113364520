$good:        #68d391;
$almost-good: #c4eb3b;
$warn:        #f6e05e;
$bad:         #ed8936;
$worst:       #e53e3e;
$no-value:    #cbd5e0;

@media screen, print {
  .indicator-good {
    background-color: $good !important;
  }

  .indicator-ok {
    background-color: $almost-good !important;
  }

  .indicator-warn {
    background-color: $warn !important;
  }

  .indicator-bad {
    background-color: $bad !important;
  }

  .indicator-worst {
    background-color: $worst !important;
  }

  .indicator-no-value {
    background-color: $no-value !important;
  }
}