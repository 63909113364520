// @import url('https://fonts.googleapis.com/css?family=Marcellus+SC|Red+Hat+Display&display=swap');

// Override default variables before the import
//$body-bg: #000;
// $primary: deepskyblue;
// $secondary:#ccc;
//$font-family-base: 'Marcellus SC', serif;
//$font-family-base: 'Red Hat Display', sans-serif;

// Import Bootstrap and its default variables
// @import '~bootstrap/scss/bootstrap.scss';

@import "~@fortawesome/fontawesome-free/css/all.css";
@import "variables";

// @import "~bootswatch/dist/flatly/variables";
@import "~bootstrap/scss/bootstrap";
// @import "~bootswatch/dist/flatly/bootswatch";

@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap");

.bg-selected {
  background-color: darken($primary, 20%) !important;
}

.bg-grey {
  background-color: #eee !important;
}

.pointer {
  cursor: pointer;
}

.with-sticky-footer {
  padding-bottom: 54px;
}

.sticky-footer {
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 99;
}

.sticky-header {
  top: 0;
  width: 100%;
  position: sticky;
  z-index: 100;
}

.flex-1 {
  flex: 1 1;
}

.flex-0 {
  flex: 0 0;
}

.text-smaller {
  font-size: $font-size-sm;
}

.overflow-y-auto {
  overflow-y: auto;
  overflow-x: hidden;
}

.roboto-condensed {
  font-family: "Roboto Condensed", sans-serif;
}

@media print {

  html,
  body {
    max-width: unset !important;
    min-width: unset !important;
  }

  body>*:not(#root) {
    display: none !important;
  }
}

@media screen {

  html,
  body {
    width: 100%;
    height: 100%;
    overscroll-behavior: none;
  }

  body {
    display: flex;
    flex-direction: column;
  }

  #root {
    flex-grow: 1;
    min-height: 0;
    flex-basis: 0;
    overflow-y: hidden;
  }

  iframe {
    border: 0;
  }
}

@import "./cassandra";
@import "./onboarding";