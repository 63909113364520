.report-header {
  display: grid;
  grid-template-rows: 0.6cm 3.5cm;
  grid-template-columns: 30% 70%;
  grid-template-areas:
    "company giuffre"
    "company infobilancio";

  .logo-giuffre {
    grid-area: giuffre;
    justify-self: end;
    img {
      height: 0.6cm;
    }
  }

  .logo-azienda {
    grid-area: company;
    justify-self: stretch;
    align-self: stretch;
    padding-left: 20px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: top;
    }
  }

  .info-bilancio {
    margin-top: 15px;
    grid-area: infobilancio;

    table {
      table-layout: fixed;
      border-collapse: collapse;
  
      h1, h2 {
        font-weight: bold;
        margin: 5px 0 5px 0;
      }
  
      h1 {
        font-size: 1.4rem;
      }
  
      h2 {
        font-size: 1.1rem;
      }
  
      td {
        vertical-align: middle;
      }
  
      td:first-child {
        width: 50%;
        text-align: right;
        padding-right: 20px
      }
  
      td:last-child {
        padding-left: 20px;
        border-left: 4px solid #0e3572;
        font-size: 13px;
      }
    }
  }
}
