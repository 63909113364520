.ThresholdsPage {
  .table-with-caption {
    page-break-inside: avoid;
    table {
      margin-bottom: 0;
    }
  }

  .atomic {
    page-break-inside: avoid;
  }

  p {
    margin-bottom: 0.5rem;
  }

  h1 {
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
  }
  h2 {
    margin-bottom: 0;
    margin-top: 0.4cm;
    font-size: 1.6rem;
    font-weight: bold;
  }
}