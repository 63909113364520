@import "../../styles/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.show-hover:hover {
  background-color: #efefef60;
}

.ws-normal {
  white-space: normal;
}

.indicator-card.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  &.oneline {
    grid-template-rows: 1fr;
  }
  column-gap: 25px;
  row-gap: 25px;
  padding: 10px;
}

@include media-breakpoint-up(md) {
  .indicator-card.wrapper {
    grid-template-columns: 1fr 1fr;
  }
}

@include media-breakpoint-up(xl) {
  .indicator-card.wrapper {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 30px;
  }
}

@include media-breakpoint-up(fhd) {
  .indicator-card.wrapper {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 40px;
  }
}

.card.indicator-card {
  padding: 10px;
  box-shadow: 8px 8px 10px rgba(1, 10, 38, 0.25);
  min-width: 0;

  p {
    margin: 0;
  }

  p:not(:last-child) {
    margin: 0 0 0.2em 0;
  }

  pre {
    white-space: normal;
  }

  .indicator-card.indicator-name {
    font-size: larger;
    text-align: center;
    min-height: 3em;
  }

  .indicator-card.indicator-value {
    font-size: xx-large;
    text-align: center;
    min-height: 2.5em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .indicator-card.space {
    flex: 1 1;
  }

  .indicator-card.indicator-input {
    display: flex;
    flex-direction: row;
    align-items: center;

    .indicator-input-name {
      font-size: smaller;
    }

    & > * {
      padding: 0 7px;
      flex: 1 1;
    }

    input:not([type="checkbox"]) {
      background: rgba(255, 255, 255, 0.3);
      border: none;
      color: $black;
      height: auto;
    }

    .form-group {
      margin-bottom: 0;
    }
  }
}

.indicator-table {
  .form-group {
    margin-bottom: 0;
  }
}
